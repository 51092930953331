@import url(https://fonts.googleapis.com/css2?family=Montserrat&family=Varela&display=swap);
body{margin:0;font-family:"Montserrat",sans-serif !important;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}body h1,body h2,body h3,body h4,body h5,body h6{font-family:"Varela",sans-serif !important}code{font-family:source-code-pro,Menlo,Monaco,Consolas,"Courier New",monospace}.form-group input[type=checkbox]{margin-right:10px}.registrations-table td{max-width:400px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}th{max-width:400px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}th:hover{cursor:pointer}.btn-primary{background-color:#1991ac !important;border-color:#1991ac !important;color:#fff}.btn-primary:hover,.btn-primary:active,.btn-primary:focus,.btn-primary:visited{border-color:#1a9cb5;background-color:#1a9cb5;text-decoration:none;opacity:.75}.footer{background-color:#f8f9fa}.mb-2.d-flex.align-items-center.row{background-color:#f9f9f9 !important;border-radius:.25rem !important;padding-top:16px !important}
.navbar-expand-lg .navbar-nav .nav-link{padding-right:.8rem;padding-left:.8rem;font-size:1.1em;font-weight:500}.navbar-expand-lg .navbar-nav .nav-link.active{color:#1991ac}.nav-tabs .nav-link{color:inherit}.nav-tabs .nav-link.active{color:#1991ac}
.email-editor-wrapper{
    border: 1px solid #ced4da;
    border-radius: .25rem;
}

.email-editor{
    padding: 5px;
}
