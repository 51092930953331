.navbar-expand-lg {
  .navbar-nav {
    .nav-link {
      padding-right: 0.8rem;
      padding-left: 0.8rem;
      font-size: 1.1em;
      font-weight: 500;

      &.active {
        color: #1991ac;
      }
    }
  }
}

.nav-tabs {
  .nav-link {
    color: inherit;

    &.active {
      color: #1991ac;
    }
  }
}