@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Varela&display=swap');

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Varela', sans-serif !important;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.form-group {
  input[type="checkbox"] {
    margin-right: 10px;
  }
}

.registrations-table {
  td {
    max-width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

th {
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
  }
}

.btn-primary {
  background-color: #1991ac !important;
  border-color: #1991ac !important;
  color: white;

  &:hover,
  &:active,
  &:focus,
  &:visited {
    border-color: #1a9cb5;
    background-color: #1a9cb5;
    text-decoration: none;
    opacity: 0.75;
  }
}

.footer {
  background-color: #f8f9fa;
}

// grey background for array fields of react json schema form
.mb-2.d-flex.align-items-center.row {
  background-color: #f9f9f9 !important;
  border-radius: 0.25rem !important;
  padding-top: 16px !important;
}
